<template>
  <div class="selectEnterprise">
    <BaseSelect v-bind="$attrs" v-on="$listeners" class="select"></BaseSelect>
    <i class="iconfont iconyonghu" @click="openEnterprise"></i>
    <add-personal
      :visible.sync="visible"
      :personalData='personalData'
      @emitVisible="visible = false"
      title="选择人员信息"
      addTitle="新增人员信息"
    ></add-personal>
  </div>
</template>
<script>
import BaseSelect from '@/components/common/base-select/base-select.vue'
import addPersonal from '@/pages/business/components/add-personal.vue'
export default {
  data () {
    return {
      visible: false,
      personalData: []
    }
  },
  components: { BaseSelect, addPersonal },
  methods: {
    // 打开企业弹窗
    openEnterprise () {
      this.visible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.selectEnterprise {
  display: flex;
  align-items: center;
  .select {
    flex: 1;
  }
  .iconyonghu {
    margin-left: 10px;
    font-size: 18px;
    color: #2862E7;
    cursor: pointer;
  }
}
</style>
